import React, {useEffect, useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import booking from "../images/booking.png";
import airbnb from "../images/airbnb.png";
import trustpilot from "../images/trustpilot.png"
import google from "../images/google.png"
import bookingLogo from "../images/booking-logo.png"
import airbnbLogo from "../images/airbnb-logo.png"
import trustpilotLogo from "../images/trustpilot-logo.png"
import googleLogo from "../images/google-logo.png"
import axios from 'axios';

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { red } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';

const MainHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    fontSize: "3.5rem",
    display: "flex",
    justifyContent: "center",
    color: "#15616D",
    textTransform: "uppercase",
    fontFamily: "Cormorant Garamond",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem"
    },
}));

interface Review {
    id: number,
    name: string,
    review_content: string,
    date: string, 
    stars: number,
    site: string
}

function Review() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const [reviews, setReviews] = useState<Review[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await axios.get('https://nodeapi-1040127590046.europe-west4.run.app/api/review');
            const data = response.data;
            setReviews(data);
            console.log(data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        } finally {
            setLoading(false);  // Loading is complete
        }
    }

    useEffect(() => {
        fetchReviews();
    }, []);

    function getLogo(site: string): string | undefined {
        if (site === "booking") {
            return bookingLogo;
        }
        if (site === "airbnb") {
            return airbnbLogo;
        }
        if (site === "trustpilot") {
            return trustpilotLogo;
        }
        return googleLogo;
    }

    const gridSpace = isSmDown ? 12 : 3;
    const reviewWidth = isSmDown ? 300 : 345;
    const fixedWidth = isSmDown ? "305px" : "350px";

    return (
        <Box sx={{width: "90%", pt: 5, display: "flex", flexDirection: "column", alignSelf:"center",  alignItems: "center", justifyContent: 'center'}}>
            <MainHeaderBox>{t("review")}</MainHeaderBox>
            <Grid container spacing={0} sx={{ pt: 5, pb: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <Grid item xs={gridSpace} sx={{display: "flex", justifyContent: "center"}}>
                    <img src={booking} style={{width:"60%"}}/>
                </Grid>
                <Grid item xs={gridSpace} sx={{display: "flex", justifyContent: "center"}}>
                    <img src={airbnb} style={{width:"60%"}}/>
                </Grid>
                <Grid item xs={gridSpace} sx={{display: "flex", justifyContent: "center"}}>
                    <img src={trustpilot} style={{width:"60%"}}/>
                </Grid>
                <Grid item xs={gridSpace} sx={{display: "flex", justifyContent: "center"}}>
                    <img src={google} style={{width:"45%"}}/>
                </Grid>
            </Grid>
            <Box sx={{width: "100%", pt: 10, pb: 10, display: "inline", alignSelf:"center",  alignItems: "center", justifyContent: 'center'}}>
                {loading ? (
                    <Box sx={{display: "flex", justifyContent: "center"}}> 
                    <LoadingButton loading sx={{outline: 'none', '&:focus': { outline: 'none' }, fontSize: "10rem"}}/>
                    </Box> 
                ) : (
                    <Splide options={{
                        type: "loop", // Loop back to the beginning when reaching the end
                        autoScroll: {
                            pauseOnHover: false, // Do not pause scrolling when hovering over the carousel
                            pauseOnFocus: false, // Do not pause scrolling when the carousel is focused
                            rewind: true, // Rewind to start when the end is reached
                            speed: 1, // Scrolling speed
                        },
                        arrows: false, // Hide navigation arrows
                        pagination: false, // Hide pagination dots
                        fixedWidth: fixedWidth, // Fixed width for each slide
                        gap: '12px', // Gap between slides
                        perPage: 2
                    }}
                    extensions={{ AutoScroll }}>
                        {reviews.map(review => (
                            <SplideSlide key={review.id}>
                            <Card sx={{width: reviewWidth, bgcolor: "white"}}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: red[900] }}>
                                        {review.name[0]}
                                    </Avatar>}
                                action={
                                    <Avatar variant="square" src={getLogo(review.site)}/>
                                }
                                title={review.name}
                                subheader={review.date}
                                />
                            <CardContent sx={{pt: 0, display: "flex", flexDirection: "column"}}>
                                <Rating value={review.stars} readOnly />
                                <Typography variant="body2" sx={{color: 'text.secondary', pt: 1, display: "flex", alignItems: "center"}}>{review.review_content}</Typography>
                            </CardContent>
                        </Card>
                        </SplideSlide>
                        ))}
                        
                </Splide>
                )}
                
            </Box>  
            {/* <Box sx={{bgcolor: "grey" ,width: "90%", pt: 10, pb: 10, display: "flex", alignSelf:"center",  alignItems: "center", justifyContent: 'center'}}>
                <Card sx={{width: 345, bgcolor: "white"}}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                K
                            </Avatar>}
                        action={
                            <Avatar variant="square" src={bookingLogo}/>
                        }
                        title="Kamile"
                        subheader="August 2024"
                        sx={{bgcolor: "red"}}
                        />
                    <CardContent sx={{bgcolor: "pink", pt: 0, display: "flex", flexDirection: "column"}}>
                        <Rating value={5} readOnly />
                        <Typography variant="body2" sx={{color: 'text.secondary', pt: 1, display: "flex", alignItems: "center"}}>Absoliučiai viskas buvo nuostabu. Nuo šeimininkų komunikacijos iki puikių švara dvelkiančių apartamentų. Viskas modernu ir gražu. Būtinai sugrįšime.</Typography>
                    </CardContent>
                </Card>
            </Box> */}
        </Box>
    )
}

export default Review;